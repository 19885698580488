import Snif from './snif';

/**
 * > When using the esBuild API, all `process.env.NODE_ENV` expressions
 * > are automatically defined to `"production"` if all minification
 * > options are enabled and `"development"` otherwise. This only happens
 * > if `process`, `process.env`, and `process.env.NODE_ENV` are not already
 * > defined. This substitution is necessary to avoid code crashing instantly
 * > (since `process` is a Node API, not a web API).
 * > — https://esbuild.github.io/api/#platform
 */
// const NODE_ENV = process.env.NODE_ENV;
const NODE_ENV = process.env.ELEVENTY_ENV;

const APP_NAME     = 'Romain MURSCHEL 2024';
// const DATA_API_KEY = '.data-api';
// const GA_CODE      = 'UA-XXXXXXXX-X';

const html         = document.documentElement;
const body         = document.body;

const CONNECTION = navigator.connection || navigator.mozConnection || navigator.webkitConnection || {};

const IS_IE             = Snif.isIE;
const IS_MOBILE         = Snif.isMobile;
// const IS_MOBILE         = window.matchMedia('(any-pointer:coarse)').matches
const IS_TOUCH          = Snif.isTouch;
const IS_DEBUG          = NODE_ENV === 'development' || html.hasAttribute('data-debug');
// const IS_ADMIN          = body.classList.contains('wp-admin') || body.classList.contains('login');
const IS_REDUCED_MOTION = window.matchMedia('(prefers-reduced-motion: reduce)').matches;


// const intersectionObserverSupport = "IntersectionObserver" in window && "IntersectionObserverEntry" in window && "intersectionRatio" in window.IntersectionObserverEntry.prototype;
// const mutationObserverSupport     = "MutationObserver" in window;
// const idleCallbackSupport         = "requestIdleCallback" in window;

// const transitionEndEventName = getTransitionEndEventName();
// function getTransitionEndEventName() {
//   var transitions = {
//     "transition"      : "transitionend",
//     "OTransition"     : "oTransitionEnd",
//     "MozTransition"   : "transitionend",
//     "WebkitTransition": "webkitTransitionEnd"
//    }
//   let bodyStyle = document.body.style;
//   for(let transition in transitions) {
//     if (bodyStyle[transition] != undefined) {
//       return transitions[transition];
//     }
//   }
// }


// Main environment variables
const ENV = Object.freeze({
    // Node environment
    NAME: NODE_ENV,
    IS_PROD: NODE_ENV === 'production',
    IS_DEV: NODE_ENV === 'development',

    // Device
    IS_MOBILE,
    IS_DESKTOP: !IS_MOBILE,
    IS_TOUCH,
    IS_IE,
    IS_DEBUG,
    // IS_ADMIN,
    IS_REDUCED_MOTION,

    CONNECTION,

    // Supports
    SUPPORTS_VH: (
        'CSS' in window
        && 'supports' in window.CSS
        && window.CSS.supports('height: 100svh')
        && window.CSS.supports('height: 100dvh')
        && window.CSS.supports('height: 100lvh')
    ),
    SUPPORT_CSSVAR: (
        'CSS' in window
        && 'supports' in window.CSS
        && window.CSS.supports('color', 'var(--fake-var)')
    ),
    SUPPORT_MIXBLENDMODE: (
        'CSS' in window
        && 'supports' in window.CSS
        && window.CSS.supports('mix-blend-mode','multiply')
    )
});

// Main CSS classes used within the project
const CSS_CLASS = Object.freeze({
    LOADING: 'is-loading',
    READY: 'is-ready',
    LOADED: 'is-loaded',
    LEAVING: 'is-leaving',

    FONTS_LOADED: 'fonts-loaded',

    NAV_OPEN: 'is-nav-open',

    LAZY_CONTAINER: 'c-lazy',
    LAZY_LOADED: '-lazy-loaded',

    HAS_SCROLL: '-has-scroll',
    SCROLL_UP: '-scroll-up',
    SCROLL_DOWN: '-scroll-down',

    CURSOR: 'cursor',
    CURSOR_CIRCLE: 'cursor_inner.-circle',
    CURSOR_MORE: 'cursor_more',
    CURSOR_READ: 'cursor_read',
    CURSOR_JS_MORE: 'js-cursor-more',
    CURSOR_JS_READ: 'js-cursor-read',
    PARVUS: 'parvbox',
    LIGHTBOX: 'lightbox',

    IN_VIEW: 'is-inview',

    REVEAL_ENDED: 'reveal-ended',

    WEBGL_CARD: 'webgl-card-canvas'
});

// EASINGS

const EASINGS = {
    // Power 1
    power1In:     [0.550, 0.085, 0.680, 0.530],
    power1Out:    [0.250, 0.460, 0.450, 0.940],
    power1InOut:  [0.455, 0.030, 0.515, 0.955],
    // Power 2
    power2In:     [0.550, 0.055, 0.675, 0.190],
    power2Out:    [0.215, 0.610, 0.355, 1.000],
    power2InOut:  [0.645, 0.045, 0.355, 1.000],
    // Power 3
    power3In:     [0.895, 0.030, 0.685, 0.220],
    power3Out:    [0.165, 0.840, 0.440, 1.000],
    power3InOut:  [0.770, 0.000, 0.175, 1.000],
    // Power 4
    power4In:     [0.755, 0.050, 0.855, 0.060],
    power4Out:    [0.230, 1.000, 0.320, 1.000],
    power4InOut:  [0.860, 0.000, 0.070, 1.000],
    // Expo
    expoIn:       [0.950, 0.050, 0.795, 0.035],
    expoOut:      [0.190, 1.000, 0.220, 1.000],
    expoInOut:    [1.000, 0.000, 0.000, 1.000],
    // Back
    backIn:       [0.600, -0.280, 0.735, 0.045],
    backOut:      [0.175, 0.885, 0.320, 1.275],
    backInOut:    [0.680, -0.550, 0.265, 1.550],
    // Sine
    sineIn:       [0.470, 0.000, 0.745, 0.715],
    sineOut:      [0.390, 0.575, 0.565, 1.000],
    sineInOut:    [0.445, 0.050, 0.550, 0.950],
    // Circ
    cirIn:        [0.600, 0.040, 0.980, 0.335],
    cirOut:       [0.075, 0.820, 0.165, 1.000],
    cirInOut:     [0.785, 0.135, 0.150, 0.860],
    // Misc
    bounce:       [0.17, 0.67, 0.3, 1.33],
    slowOut:      [.04,1.15,0.4,.99],
    smooth:       [0.380, 0.005, 0.215, 1],
    // Custom RM
    smoothCustom: [0.4,0.01,0.23,0.98],
    custom:       [.7,.1,.3,.9],
};

// Events name
const EVENTS = Object.freeze({
    MOVE:   IS_TOUCH ? 'touchmove' : 'mousemove',
    UP:     IS_TOUCH ? 'touchend' : 'mouseup',
    DOWN:   IS_TOUCH ? 'touchstart' : 'mousedown',
    RESIZE: 'resize',
    SCROLL: 'scroll',
    WHEEL:  'wheel'
});

// Custom js events
const CUSTOM_EVENTS = Object.freeze({
    RESIZE_END: 'rm.resizeEnd',
    UPDATE_FPS: 'rm.updateFPS',
});

// Fonts parameters
const FONT = Object.freeze({
    EAGER: [
        { family: 'Futura PT', style: 'normal', weight: 400 },
        { family: 'Futura PT', style: 'normal', weight: 300 },
        { family: 'HV Clio', style: 'normal', weight: 400 },
        //   { family: 'Source Sans', style: 'normal', weight: 700 },
    ],
})

export {
    ENV,
    APP_NAME,
    // DATA_API_KEY,
    // GA_CODE,

    html, body,

    CSS_CLASS,
    EASINGS,

    EVENTS,
    CUSTOM_EVENTS,
    FONT,
};
