import { body, CSS_CLASS } from '../utils/environment';

let navIsOpen = false;

export class Navigation {

    constructor({ toggle }) {
        // debug('init Navigation');
        this.toggle = toggle;
        this.isOpen = false;

        this.toggle.addEventListener('click', this.handleToggle);
    }

    get type() {
        return 'navigation';
    }

    // get isOpen() {
    //   return this.isOpen;
    // }

    handleToggle(e) {
        // debug('toggle nav fired !', e, this);
        body.classList.toggle(CSS_CLASS.NAV_OPEN);
        // this.isOpen = !this.isOpen;
        navIsOpen = !navIsOpen;
    }

    close() {
        body.classList.remove(CSS_CLASS.NAV_OPEN);
        // this.isOpen = false;
        navIsOpen = false;
    }


    //--- Hooks

    onPageOut() {
        // debug('onPageOut from Navigation', navIsOpen);
        // if (this.isOpen) {
        if (navIsOpen) {
            this.close();
        }
    }
}
