// import svg4everybody from 'svg4everybody';
import { ENV }          from './utils/environment';
// import { isDefined }    from './utils/is';
// import { loadScript }   from './utils/html';
import { inView }       from 'motion';

// Dynamic imports for development mode only
let gridHelper = null;
(async () => {
    if (ENV.IS_DEV) {
        const gridHelperModule = await import('./utils/grid-helper');
        // gridHelper = gridHelperModule?.gridHelper;
        if (gridHelperModule) gridHelper = gridHelperModule.gridHelper;
    }
})();

export default function () {
    /**
     * Use external SVG spritemaps
     */
    // svg4everybody();

    /**
     * Add grid helper
     */
    if (gridHelper) { gridHelper(); }
    // gridHelper?.();

    // Init lazyload of videos
    initLazyVideos();

    // Init Third Party Scripts
    // initThirdPartyScripts(); // Deplaced to <head> section via Eleventy transform
}


function initLazyVideos() {
    const lazy_videos = [...document.querySelectorAll('video.lazy')];

    if (lazy_videos.length) {
        inView(lazy_videos, (info) => {
            // debug(info);

            const video = info.target;
            let sources = video.querySelectorAll('source');
            sources.forEach(source => {
                let src = source.dataset.src;
                if (src) {
                    source.setAttribute('src', src);
                }
            });

            video.load();
            video.classList.remove('lazy');
            video.classList.add('lazyloaded');
            // video.play();
        }, {
            margin: "-10% 0px -10% 0px"
        });
    }

    // const lazy_videos = [...document.querySelectorAll('.poster-video')];

    // if (lazy_videos.length) {
    //     inView(lazy_videos, (info) => {
    //         const lazyContainer = info.target;
    //         const videoSource   = isDefined(lazyContainer.dataset.videoSource) ? lazyContainer.dataset.videoSource : null;
    //         const videoType     = isDefined(lazyContainer.dataset.videoType) ? lazyContainer.dataset.videoType : null;
    //         const videoWidth    = isDefined(lazyContainer.dataset.videoWidth) ? lazyContainer.dataset.videoWidth : null;
    //         const videoHeight   = isDefined(lazyContainer.dataset.videoHeight) ? lazyContainer.dataset.videoHeight : null;

    //         if (videoSource && videoType) {
    //             // remove the img
    //             // lazyContainer.removeChild(img);

    //             // create a video
    //             const sourceElement = document.createElement('source');
    //             sourceElement.src = videoSource;
    //             sourceElement.type = videoType;

    //             const videoElement = document.createElement('video');
    //             // videoElement.alt = "Lazy-loaded Video";
    //             // videoElement.poster =
    //             //     "your_placeholder_image.jpg";
    //             videoElement.controls = false;
    //             videoElement.preload = 'none';
    //             videoElement.muted = true;
    //             videoElement.playsinline = true;
    //             videoElement.autoplay = true;
    //             videoElement.loop = true;
    //             if (videoWidth) {
    //                 videoElement.width = videoWidth
    //             }
    //             if (videoHeight) {
    //                 videoElement.height = videoHeight
    //             }
    //             videoElement.appendChild(sourceElement);

    //             const pElement = document.createElement('p');
    //             pElement.innerHTML = 'Votre navigateur ne prend pas en charge les vidéos HTML5. Voici <a href="'+videoSource+'" rel="nofollow">un lien pour télécharger la vidéo</a>.';
    //             videoElement.appendChild(pElement);

    //             // swap it in for the img
    //             // lazyContainer.innerHTML = '';
    //             // while (lazyContainer.firstChild) {
    //             //     lazyContainer.removeChild(lazyContainer.lastChild);
    //             // }
    //             lazyContainer.appendChild(videoElement);
    //             // load video
    //             videoElement.load();
    //         }
    //     }, {
    //         margin: "-10% 0px -10% 0px"
    //     });
    // }
}
