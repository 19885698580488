import { ENV } from './environment';

export default function(val, val2, val3) {
  if (ENV.IS_DEBUG || ENV.IS_ADMIN) {
    if (val3) {
      console.log(val, val2, val3);
    }
    else if (val2) {
      console.log(val, val2);
    } else {
      console.log(val);
    }
  }
}
